export enum UserTypes {
  FACILITY_ADMIN = 'FACILITY_ADMIN',
  FACILITY_USER = 'FACILITY_USER',
  SUPER_ADMIN = 'SUPER_ADMIN',
  RESIDENT = 'RESIDENT',
  TV_USER = 'TV_USER',
}

export enum UserPermissions {
  VIEW_RESIDENTS = 'VIEW_RESIDENTS',
  EDIT_RESIDENTS = 'EDIT_RESIDENTS',
}

export const APOLLO_USER_GROUPS = Object.assign({}, UserTypes, UserPermissions);
export type APOLLO_USER_GROUPS_KEYS = keyof typeof APOLLO_USER_GROUPS;

export type userPermissions = UserPermissions | UserTypes;