import { Dictionary } from './global.model'

export enum facilityAddOns {
  SUGGESTED_CONTENT = 'SUGGESTED_CONTENT',
  FAVOURITES = 'FAVOURITES',
  FAMILY = 'FAMILY',
  DEMENTIA = 'DEMENTIA',
  TEMPORARY_LOCK = 'TEMPORARY_LOCK',
  WHO_IS_LISTENING = 'WHO_IS_LISTENING',
  TV = 'TV',
  TV_REMOTE_CONTROL = 'TV_REMOTE_CONTROL',
}

export const addonsViewValues = {
  SUGGESTED_CONTENT: 'Suggested Content',
  FAVOURITES: 'Favourites',
  FAMILY: 'Life Story',
  DEMENTIA: 'Dementia',
  TEMPORARY_LOCK: 'Temporary Lock',
  WHO_IS_LISTENING: 'Who is Listening',
  TV: 'TV',
  TV_REMOTE_CONTROL: 'Remote TV Control',
}


export const availableLicenses: Dictionary<string> = {
  '-1': 'None',
  0: 'Demo plan',
  1: '5 streams',
  2: '10 streams',
  3: '15 streams',
  4: '20 streams',
  5: '25 streams'
}

export interface Facility {
  id: string;
  organisationId: string;
  name: string;
  displayName: string;
  legacyFacilityId?: string;
  passwordResetEmail?: string;
  address: Address;
  country: string;
  license: number;
  addOns: facilityAddOns[];
  residentsUploadStatus: 'PENDING' | undefined;
  subscription: MediaSubscription
  temporaryUnlockCode?: string;
  residentLimit: string;
}
export interface Address {
  address: string;
  city: string;
  state: string;
  postcode: string;
  country: string;
}

export interface MediaSubscription {
  provider: string;
  activatedDateUTC: string;
  startDateUTC: string;
  expiryDateUTC: string;
  userId: string;
  planCode: string;
  country: string;
  recurringFee: number;
  currency: string;
  subscriptionStartDate: string;
  status: MediaSubscriptionStatus;
  autoRenew: boolean;
}

export enum MediaSubscriptionStatus {
  ACTIVE = 'ACTIVE',
  SCHEDULED = 'SCHEDULED',
  EXPIRED = 'EXPIRED',
  CANCELLED = 'CANCELLED',
  NONE = 'NONE',
}
