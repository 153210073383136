export enum MediaType {
  VIDEO = 'VIDEO',
  MUSIC = 'MUSIC',
}

export enum MediaStatus  {
  ARCHIVED = 'ARCHIVED',
  PUBLISHED = 'PUBLISHED',
  DRAFT = 'DRAFT',
  UNAVAILABLE = 'UNAVAILABLE'
}

export interface MediaTag {
  name: string;
  isCollection: boolean;
  weight?: number;
}
