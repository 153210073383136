import { MediaStatus, MediaTag, MediaType } from './media.model';

export enum CategoryType {
  VIDEO = 'VIDEO',
  MUSIC = 'MUSIC',
}


export interface VideoCategory {
  category: string;
  slug: string;
  parentCategory?: string;
  color?: string;
  favourite?: boolean;
  childCategories?: number;
  childItems?: number;
  media?: VideoItem;
  primary?: PrimaryCategory;
  hide: boolean;
  sort: number;
}

export interface VideoItem {
  id: string;
  source: MediaSource;
  sourceRef?: string;
  duration?: string;
  title: string;
  kind?: VideoKind;
  type?: MediaType.VIDEO,
  thumbnail: string;
  status?: MediaStatus;
  tags?: MediaTag[];
  languages?: string[];
}


export interface MusicCategory {
  slug: string;
  category: string;
  parentCategory?: string;
  // color?: string; // are we using this?
  childCategories?: number;
  childItems?: number;
  media?: MusicItem;
  primary?: PrimaryCategory;
  hide: boolean;
  sort: number;
  favourite?: boolean;
}

export interface MusicItem {
  source: MediaSource;
  sourceRef: string;
  id: string;
  title: string;
  type?: MediaType.MUSIC,
  duration?: string;
  lastUpdated: number;
  artist: string;
  thumbnail: string
  tracks: MusicTrack[];
  tags?: MediaTag[];

}

export interface Artist {
  id: string;
  name: string;
  slug: string;
}


export interface MusicTrack {
  id: string;
  title: string;
  artist: string;
  duration: number;
  releaseYear: number;
  releaseId: string;
  artwork: string;
  _loading: boolean;
  _playing: boolean;
  _preload?: boolean;
}

export interface PrimaryCategory {
  color: string;
  order: number;
  icon: string;
}


// export enum MusicSource {
//   SEVEN_DIGITAL = 'SEVEN_DIGITAL',
//   YOUTUBE = 'YOUTUBE',
//   UNKNOWN = 'UNKNOWN',
// }
// export enum VideoSource {
//   YOUTUBE = 'YOUTUBE',
//   VIMEO = 'VIMEO',
// }

export enum MediaSource {
  SEVEN_DIGITAL = 'SEVEN_DIGITAL',
  YOUTUBE = 'YOUTUBE',
  VIMEO = 'VIMEO',
}

export enum VideoKind {
  VIDEO = 'VIDEO',
  VIDEO_PLAYLIST = 'VIDEO_PLAYLIST',
}


export interface MusicStream {
  ref?: string;
  expires?: number;
  issuedAt?: number;
  message?: string;
  subscriptionId?: string;
  status: 'ERROR' | 'SUCCESS';
}